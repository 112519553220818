import { FormLayout } from "@shopify/polaris";
import * as Yup from "yup";

import { Input } from "~/components";
import { IntegrationModal, IntegrationModalProps } from "../IntegrationModal";

const schema = Yup.object().shape({
  privateKey: Yup.string().required(),
});

export const MailchimpModal = (
  props: Omit<
    IntegrationModalProps,
    "getDefaultValues" | "schema" | "renderInputs"
  >
) => {
  return (
    <IntegrationModal
      {...props}
      getDefaultValues={(integration) => ({
        privateKey: "",
      })}
      schema={schema}
      renderInputs={(control, errors, integration) => (
        <FormLayout>
          <Input
            name="privateKey"
            control={control}
            label="API Key"
            error={errors?.privateKey?.message}
            labelAction={{
              content: "Where do I find this?",
              url: "https://help.trylantern.com/en/articles/7144626-mailchimp-integration",
              external: true,
            }}
            placeholder={
              integration?.hasPrivateKey
                ? "•••••••••••••••••••"
                : "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-xxx"
            }
            type="password"
            autoComplete="new-password"
          />
        </FormLayout>
      )}
    />
  );
};
