import {
  Checkbox,
  RangeSlider,
  Select,
  Text,
  Tooltip,
  Stack,
  Button,
  Collapsible,
  TextField,
  Link,
} from "@shopify/polaris";
import {
  ChannelsIcon,
  LinkIcon,
  ProductIcon,
  SettingsIcon,
  ThemeTemplateIcon,
} from "@shopify/polaris-icons";
import { Input } from "@storyofams/react-ui";
import { range } from "lodash";
import { Controller, useWatch } from "react-hook-form";
import { Section } from "~/components/Section";
import { useOptionsForm } from "~/hooks";
import { useState } from "react";
import styled from "styled-components";

const resultsOptions = range(10).map((idx) => ({
  label: `${idx + 1} result${idx > 0 ? "s" : ""}`,
  value: `${idx + 1}`,
}));

const extraOptions = range(20).map((idx) => ({
  label: `${idx + 1} result${idx > 0 ? "s" : ""}`,
  value: `${idx + 1}`,
}));

const priorityOptions = [
  { label: "Lowest Price", value: "LOWEST" },
  { label: "Highest Price", value: "HIGHEST" },
  { label: "A-Z", value: "AZ" },
  { label: "Z-A", value: "ZA" },
  { label: "Random", value: "RANDOM" },
];

const formatOptions = [
  { label: "8 / 10", value: "NUMBER" },
  { label: "80%", value: "PERCENTAGE" },
  { label: "-- hide --", value: "HIDE" },
];

const getDefaultValues = (values) => ({
  enableRestart:
    typeof values?.enableRestart !== "undefined"
      ? !!values.enableRestart
      : true,
  enableReviews:
    typeof values?.enableReviews !== "undefined"
      ? !!values.enableReviews
      : true,
  returnToResults:
    typeof values?.returnToResults !== "undefined"
      ? !!values.returnToResults
      : true,
  reviewsSource: `${values?.reviewsSource || "shopify"}`,
  cartPage: `${values?.cartPage || "default"}`,
  subscriptionsService: `${values?.subscriptionsService || "none"}`,
  defaultSubscriptionsOption: `${
    values?.defaultSubscriptionsOption || "subscribe-save"
  }`,
  oneTimeLabel: `${values?.oneTimeLabel || "One-Time Purchase"}`,
  subscribeSaveLabel: `${values?.subscribeSaveLabel || "Subscribe & Save"}`,
  showSubscriptionsHelpIcon:
    typeof values?.showSubscriptionsHelpIcon !== "undefined"
      ? !!values.showSubscriptionsHelpIcon
      : true,
  subscriptionsHelpText: `${
    values?.subscriptionsHelpText ||
    "Products are automatically delivered on your schedule. No obligation, modify or cancel your subscription anytime."
  }`,
  enableMoreResults:
    typeof values?.enableMoreResults !== "undefined"
      ? !!values.enableMoreResults
      : true,
  enableAddToCart:
    typeof values?.enableAddToCart !== "undefined"
      ? !!values.enableAddToCart
      : true,
  enableAddAll:
    typeof values?.enableAddAll !== "undefined" ? !!values.enableAddAll : false,
  hideDefaultRecommendations:
    typeof values?.hideDefaultRecommendations !== "undefined"
      ? !!values.hideDefaultRecommendations
      : false,
  useURLTrackingParameters:
    typeof values?.useURLTrackingParameters !== "undefined"
      ? !!values.useURLTrackingParameters
      : true,
  customUTM: `${values?.customUTM || "utm_source=lantern&utm_campaign={slug}"}`,
  showProductDescription:
    typeof values?.showProductDescription !== "undefined"
      ? !!values.showProductDescription
      : false,
  showShortDescription:
    typeof values?.showShortDescription !== "undefined"
      ? !!values.showShortDescription
      : false,
  customDescriptionMetafield: `${
    values?.customDescriptionMetafield || "lantern_description"
  }`,
  showVisualMatchBlock:
    typeof values?.showVisualMatchBlock !== "undefined"
      ? !!values.showVisualMatchBlock
      : false,
  resultsAmount: `${values?.resultsAmount || 3}`,
  extraAmount: `${values?.extraAmount || 6}`,
  enableSoldOutProducts:
    typeof values?.enableSoldOutProducts !== "undefined"
      ? !!values.enableSoldOutProducts
      : true,
  openInNewTab:
    typeof values?.openInNewTab !== "undefined" ? !!values.openInNewTab : true,
  tagOrders:
    typeof values?.tagOrders !== "undefined" ? !!values.tagOrders : true,
  customTag: values?.customTag || "Lantern Quiz",
  createCustomer:
    typeof values?.createCustomer !== "undefined"
      ? !!values.createCustomer
      : false,
  addCustomerTags:
    typeof values?.addCustomerTags !== "undefined"
      ? !!values.addCustomerTags
      : false,
  doubleOptIn:
    typeof values?.doubleOptIn !== "undefined" ? !!values.doubleOptIn : true,
  customerTags: values?.customerTags || "lantern,quiz",
  resultSortingPriority:
    typeof values?.resultSortingPriority !== "undefined"
      ? values.resultSortingPriority
      : priorityOptions[0].value,
  resultsPerRow:
    typeof values?.resultsPerRow !== "undefined" ? values.resultsPerRow : 3,
  resultsPerRowMobile:
    typeof values?.resultsPerRowMobile !== "undefined"
      ? values.resultsPerRowMobile
      : 1,
  matchThreshold:
    typeof values?.matchThreshold !== "undefined" ? values.matchThreshold : 0,
  weightThreshold:
    typeof values?.weightThreshold !== "undefined" ? values.weightThreshold : 0,
  matchFormat:
    typeof values?.matchFormat !== "undefined" ? values.matchFormat : "NUMBER",
  resultsRedirectUrl:
    typeof values?.resultsRedirectUrl !== "undefined"
      ? values.resultsRedirectUrl
      : "",
  viewMoreRedirectUrl:
    typeof values?.viewMoreRedirectUrl !== "undefined"
      ? values.viewMoreRedirectUrl
      : "",
  noResultsRedirectUrl:
    typeof values?.noResultsRedirectUrl !== "undefined"
      ? values.noResultsRedirectUrl
      : "",
});

export const ResultsOptions = () => {
  const { control } = useOptionsForm({
    getDefaultValues,
    formatValues: (values) => ({
      ...values,
      resultsAmount: parseInt(values.resultsAmount, 10),
      extraAmount: parseInt(values.extraAmount, 10),
      resultsPerRow: parseInt(values.resultsPerRow, 10),
      resultsPerRowMobile: parseInt(values.resultsPerRowMobile, 10),
      matchThreshold: parseInt(values.matchThreshold, 10),
      weightThreshold: parseInt(values.weightThreshold, 10),
    }),
    type: "flow",
  });

  const [settings, setSettings] = useState(0);

  return (
    <SettingsContainer>
      <Stack vertical distribution="leading" alignment="fill">
        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 2}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={ThemeTemplateIcon as any}
        >
          Show/Hide Elements
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ShowHideSettings control={control} />
        </Collapsible>

        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 1}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={ChannelsIcon as any}
        >
          Results Integrations
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <IntegrationSettings control={control} />
        </Collapsible>

        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 3}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={ProductIcon as any}
        >
          Product Recommendations
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ProductRecommendationsSettings control={control} />
        </Collapsible>

        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 4}
          disclosure={settings === 4 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 4 ? 0 : 4))}
          icon={LinkIcon as any}
        >
          URLs
        </Button>
        <Collapsible
          open={settings === 4}
          id="collapsible-4"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <URLSettings control={control} />
        </Collapsible>

        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 5}
          disclosure={settings === 5 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 5 ? 0 : 5))}
          icon={SettingsIcon as any}
        >
          Advanced Settings
        </Button>
        <Collapsible
          open={settings === 5}
          id="collapsible-5"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <AdvancedSettings control={control} />
        </Collapsible>
      </Stack>
    </SettingsContainer>
  );
};

const IntegrationSettings = ({ control }) => {
  const enableReviews = useWatch({
    control,
    name: "enableReviews",
  });

  return (
    <Section>
      <Text variant="bodyLg" as="p" fontWeight="bold">
        Reviews
      </Text>

      <Controller
        control={control}
        name="enableReviews"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show product reviews" checked={value} {...field} />
        )}
      />

      {!!enableReviews && (
        <Controller
          control={control}
          name="reviewsSource"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Reviews app you use"
              options={[
                { label: "Shopify", value: "shopify" },
                { label: "Yotpo", value: "yotpo" },
                { label: "Judge.me", value: "judgeme" },
                { label: "Loox", value: "loox" },
                { label: "Stamped", value: "stamped" },
                { label: "Junip", value: "junip" },
                { label: "Opinew", value: "opinew" },
                { label: "Ryviu", value: "ryviu" },
                { label: "Rivyo", value: "rivyo" },
                { label: "Reviews.io", value: "reviewsio" },
                { label: "Okendo", value: "okendo" },
              ]}
              {...field}
            />
          )}
        />
      )}

      <Text variant="bodyLg" as="p" fontWeight="bold">
        Side Cart / Cart Drawer
      </Text>

      <Controller
        control={control}
        name="cartPage"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="When adding to cart, open:"
            options={[
              { label: "Default Cart Page", value: "default" },
              { label: "Rebuy Smart Cart", value: "rebuy" },
              { label: "Dawn Cart Drawer", value: "dawn" },
              { label: "Impact Cart Drawer", value: "impact" },
              { label: "Focal Cart Drawer", value: "focal" },
              { label: "Split Cart Drawer", value: "split" },
              { label: "Corner Cart Drawer", value: "corner" },
              { label: "Concept Cart Drawer", value: "concept" },
            ]}
            {...field}
          />
        )}
      />

      <Text variant="bodyLg" as="p" fontWeight="bold">
        Subscriptions
      </Text>

      <Controller
        control={control}
        name="subscriptionsService"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Subscriptions app you use"
            options={[
              { label: "None", value: "none" },
              {
                label: "Shopify Subscriptions",
                value: "shopify_subscriptions",
              },
              { label: "Recharge", value: "recharge" },
              { label: "Skio", value: "skio" },
              { label: "Seal", value: "seal" },
              { label: "Ordergroove", value: "ordergroove" },
              {
                label: "Utterbond",
                value: "utterbond_subscriptions",
              },
              {
                label: "Loop",
                value: "loop_subscriptions",
              },
              { label: "Appstle", value: "appstle" },
              { label: "Subify", value: "subify" },
              { label: "Bold", value: "bold" },
              { label: "PayWhirl", value: "paywhirl" },
              { label: "Super", value: "super" },
              { label: "Yotpo", value: "yotpo_subscriptions" },
              { label: "Recurpay", value: "recurpay" },
              { label: "Casa", value: "casa" },
              { label: "Smartrr", value: "smartrr_subscriptions" },
              { label: "Propel", value: "propel" },
              { label: "Subscription Plus", value: "subscription_plus" },
            ]}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="defaultSubscriptionsOption"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Default Selection"
            options={[
              { label: "Subscribe & Save", value: "subscribe-save" },
              { label: "One-Time Purchase", value: "one-time" },
            ]}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="oneTimeLabel"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="One-Time Purchase Label"
            autoComplete="off"
            placeholder="One-Time Purchase"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="subscribeSaveLabel"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Subscribe & Save Label"
            autoComplete="off"
            placeholder="Subscribe & Save"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="showSubscriptionsHelpIcon"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show help icon" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="subscriptionsHelpText"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Help Text"
            autoComplete="off"
            placeholder="Products are automatically delivered on your schedule. No obligation, modify or cancel your subscription anytime."
            multiline={3}
            {...field}
          />
        )}
      />
    </Section>
  );
};

const ProductRecommendationsSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="hideDefaultRecommendations"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Hide default recommendations"
            checked={value}
            helpText="Use this option to only recommend products through Dynamic Content Blocks."
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsAmount"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Total Product Recommendations"
            options={resultsOptions}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsPerRow"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Results Per Row (Desktop)"
            min={1}
            max={10}
            step={1}
            {...field}
            prefix={`${field.value}`}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsPerRowMobile"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Results Per Row (Mobile)"
            min={1}
            max={10}
            step={1}
            {...field}
            prefix={`${field.value}`}
          />
        )}
      />

      <Controller
        control={control}
        name="matchThreshold"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Match Score Threshold"
            min={0}
            max={100}
            step={5}
            {...field}
            suffix={`${field.value}%`}
            helpText="The minimum match score a product must have to be shown in the results."
          />
        )}
      />

      <Controller
        control={control}
        name="weightThreshold"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Total Weight Threshold"
            min={0}
            max={100000}
            step={100}
            {...field}
            suffix={`${field.value}`}
            helpText="Only if you use custom answer weights - the minimum total weight a product must have to be shown in the results."
          />
        )}
      />

      <Controller
        control={control}
        name="matchFormat"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Match Score Format"
            options={formatOptions}
            {...field}
          />
        )}
      />

      <Tooltip
        content={
          <div>
            Determine which result to show first in case the match count is the
            same across several results. For example, if three of your results
            have a 8/10 match, this setting will determine the order those three
            results are displayed.
          </div>
        }
      >
        <Controller
          control={control}
          name="resultSortingPriority"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Sorting Priority"
              options={priorityOptions}
              {...field}
            />
          )}
        />
      </Tooltip>
    </Section>
  );
};

const URLSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="openInNewTab"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Open links in new tab" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="useURLTrackingParameters"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Use URL tracking parameters"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="customUTM"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Custom UTM parameters"
            placeholder="utm_source=lantern&utm_campaign={slug}"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Instead of showing results, redirect to this URL"
            placeholder="https://example.com"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="viewMoreRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="When clicking on the View More button, redirect to this URL"
            placeholder="leave blank to show more products"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="noResultsRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="When no results are found, link to this URL"
            placeholder="/products"
            {...field}
          />
        )}
      />
    </Section>
  );
};

const AdvancedSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="tagOrders"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Tag orders" checked={value} {...field} />
        )}
      />
      <Controller
        control={control}
        name="customTag"
        render={({ field: { ref, ...field } }) => (
          <Input label="Tag name" placeholder="Lantern Quiz" {...field} />
        )}
      />
      <Controller
        control={control}
        name="createCustomer"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Create Customer in Shopify"
            checked={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="doubleOptIn"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Customer needs to confirm marketing consent via email"
            checked={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="addCustomerTags"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Add Customer Tags" checked={value} {...field} />
        )}
      />
      <Controller
        control={control}
        name="customerTags"
        render={({ field: { ref, ...field } }) => (
          <Input label="Customer Tags" placeholder="Lantern Quiz" {...field} />
        )}
      />
      <Controller
        control={control}
        name="returnToResults"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Return to results page automatically"
            checked={value}
            {...field}
          />
        )}
      />
    </Section>
  );
};

const ShowHideSettings = ({ control }) => {
  const enableMoreResults = useWatch({
    control,
    name: "enableMoreResults",
  });

  return (
    <Section>
      <Controller
        control={control}
        name="enableRestart"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show start over" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="enableMoreResults"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show more results" checked={value} {...field} />
        )}
      />

      {!!enableMoreResults && (
        <Controller
          control={control}
          name="extraAmount"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Total Extra Results"
              options={extraOptions}
              {...field}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="enableAddToCart"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show add to cart" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="enableAddAll"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show Add All To Cart button"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="enableSoldOutProducts"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show sold out products" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="showProductDescription"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show product description"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="showShortDescription"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show short description from product metafields"
            helpText={
              <>
                Learn more about{" "}
                <Link
                  url="https://help.trylantern.com/en/articles/8983472-add-a-short-description-using-product-metafields"
                  external
                >
                  the description metafield.
                </Link>
              </>
            }
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="customDescriptionMetafield"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Metafield name"
            placeholder="lantern_description"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="showVisualMatchBlock"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show match block above ATC button"
            checked={value}
            {...field}
          />
        )}
      />
    </Section>
  );
};

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }

  & .Polaris-Button__Icon {
    margin-left: 0 !important;
  }
`;
