import { useState } from "react";
import { Button } from "@shopify/polaris";
import { Box, Flex } from "@storyofams/react-ui";
import { QuestionDeleteModal } from "~/components/QuestionDeleteModal";
import { FlowNodeType } from "~/graphql/sdk";
import { useFlow, useNavParams } from "~/hooks";

import { EmailOptions } from "./EmailOptions";
import { QuestionOptions } from "./QuestionOptions";
import { ResultsOptions } from "./ResultsOptions";
import { WelcomeOptions } from "./WelcomeOptions";
import InputOneLineTextOptions from "./InputOneLineTextOptions";
import InputMultiLineTextOptions from "./InputMultiLineTextOptions";
import TransitionOptions from "./TransitionOptions";
import { useQueryClient } from "react-query";
import { useSdk } from "~/hooks";

export const OptionsPane = () => {
  const { data, flowNode } = useFlow();
  const [{ question: current }] = useNavParams();
  const sdk = useSdk();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<number | boolean>(
    false
  );

  const flow = data?.flows?.[0];
  const isWelcome = flow?.nodes?.[current]?.type === FlowNodeType.Welcome;
  const isEmail = flow?.nodes?.[current]?.type === FlowNodeType.Email;
  const isResults = !flow?.nodes?.[current];

  const isStandardQuestion =
    flow?.nodes?.[current]?.type === FlowNodeType.Emoji ||
    flow?.nodes?.[current]?.type === FlowNodeType.EmojiMulti ||
    flow?.nodes?.[current]?.type === FlowNodeType.Image ||
    flow?.nodes?.[current]?.type === FlowNodeType.ImageMulti ||
    flow?.nodes?.[current]?.type === FlowNodeType.Simple ||
    flow?.nodes?.[current]?.type === FlowNodeType.SimpleMulti;

  const isInputOneLineText =
    flow?.nodes?.[current]?.type === FlowNodeType.InputOneLineText;

  const isInputMultiLineText =
    flow?.nodes?.[current]?.type === FlowNodeType.InputMultiLineText;

  const isTransitionScreen =
    flow?.nodes?.[current]?.type === FlowNodeType.Transition;

  return (
    <>
      <Box flex="1">
        {isWelcome && <WelcomeOptions />}

        {isStandardQuestion && <QuestionOptions key={flowNode?.id} />}

        {isInputOneLineText && <InputOneLineTextOptions key={flowNode?.id} />}

        {isInputMultiLineText && (
          <InputMultiLineTextOptions key={flowNode?.id} />
        )}

        {isTransitionScreen && <TransitionOptions key={flowNode?.id} />}

        {isEmail && <EmailOptions />}

        {isResults && <ResultsOptions />}

        {!isResults ? (
          <Flex
            justifySelf="flex-center"
            flexDirection="column"
            justifyContent="center"
            mx={3}
            my={3}
          >
            <Button
              size="slim"
              primary
              onClick={async () => {
                await sdk.cloneFlowNode({
                  input: {
                    nodeId: flowNode?.id as string,
                  },
                });
                queryClient.invalidateQueries(["container"]);
              }}
            >
              Clone Question
            </Button>
          </Flex>
        ) : null}

        {!isResults ? (
          <Flex
            justifySelf="flex-center"
            flexDirection="column"
            justifyContent="center"
            mt={3}
            pb={2}
          >
            <Button
              destructive
              plain
              onClick={() => {
                setDeleteModalOpen(current);
              }}
            >
              Remove{" "}
              {isWelcome
                ? "Welcome Screen"
                : isEmail
                ? "Email Capture Page"
                : isTransitionScreen
                ? "Transition Screen"
                : "Question"}
            </Button>
          </Flex>
        ) : null}
      </Box>

      {!!flow && (
        <QuestionDeleteModal
          isOpen={isDeleteModalOpen}
          setOpen={setDeleteModalOpen}
          currentFlow={flow}
        />
      )}
    </>
  );
};
